import commonHelper from '@/app/utility/common.helper.utility';
import ProjectSectorDetails from './projectSectorDetails';
export default {
    name: 'ProjectSector',
    watch: {
        currentPage: function() {
            this.getProjectSectorList();
        },
        perPage: function() {
            this.currentPage = 1;
            this.getProjectSectorList();
        }
    },
    props: ['prjMasterProjName', 'prjMasterProjId', 'showCrmSectorModal'],
    components: {
        ProjectSectorDetails
    },
    data() {
        return {
            unsubscribe: null,
            showModalComponent: false,
            showValueSetModal: false,
            loader: false,
            editMode: false,
            showAlert: false,
            payload:{},
            currentPage: 1,
            totalRows: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            tab: null,
            masterPrj: {
                value: null,
                text: null,
            },
            sectorCode: null,
            sectorName: null,
            parentProjectSector: null,
            projectSectorData: [],
            projectSectorFields: [
                {
                    key: 'master_project_id',
                    class: 'd-none'
                },
                {
                    key: 'master_project_name'
                },
                {
                    key: 'sector_id',
                    class: 'd-none'
                },
                {
                    key: 'sector_code'
                },
                {
                    key: 'sector_name'
                },
                {
                    key: 'start_date'
                },
                {
                    key: 'end_date'
                },
                {
                    key: 'version'
                },
                {
                    key: 'approval_status'
                },
                {
                    key: 'sequence'
                }
            ]
        }
    },
    mounted() {
        if(this.showCrmSectorModal){
            this.masterPrj.text = this.prjMasterProjName
        }
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if(mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if(actionName === 'add') {
                    this.hideModalComponent(true, 'ProjectSectorDetails');
                    this.parentProjectSector = null;
                }
                if (actionName === 'download' && !this.showModalComponent) 
          {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'projects/getProjectSectorList',
              'project-sector',
              () => (this.loader = false)
            );
          }
            }
        });
        this.getProjectSectorList();
    },
    methods: {
        setChildName(tab) {
            //method for child modal name
            if (tab === 'ProjectSectorDetails') {
              return (this.childName = 'Project Sector Details');
            }
        },
        getProjectSectorList() {
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                master_project_id: this.prjMasterProjId? this.prjMasterProjId: this.masterPrj.value,
                sector_name: this.sectorName,
                sector_code: this.sectorCode
            };
            this.loader = true;
            this.$store.dispatch('projects/getProjectSectorList', this.payload)
            .then(response => {
                this.loader = false;
                if(response.status === 200) {
                    this.projectSectorData = response.data.data.page;
                    this.totalRows = response.data.data.total_elements;
                }
            })
            .catch(()=> {
                this.loader = false;
            });
        },
        hideModalComponent(flag, component) {
            this.showModalComponent = flag;
            this.tab = component;
            this.setChildName(this.tab);
        },
        rowSelected(rowData){
        this.parentProjectSector = rowData
        this.$emit('prjSectorDetails', this.parentProjectSector);
        this.hideModalComponent(true, 'ProjectSectorDetails');
        },
        selectedvalueSet(item) {
          if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
            this.masterPrj ={
                value: item.value_set_dtl_id,
                text: item.value_code
            }
        }
        },
        openValueSetModal(vsetCode) {
          this.vsetCode = vsetCode;
          this.showValueSetModal = true;
          this.setTimeVsetCode = setTimeout(() => {
            const vSetData = {
              valueSetName: vsetCode,
              multiFlag: null
            };
            this.eventBus.$emit('valueSetCode', vSetData);
          }, 0);
        },
        closeValueSetModal() {
          this.showValueSetModal = false;
        },
        updateProjectSectorList(){
            this.getProjectSectorList();
        },
        resetSearchFilters() {
            this.masterPrj = {
                value: null,
                text: null
            };
            this.sectorCode= null;
            this.sectorName= null;
            this.getProjectSectorList();
        },
        clearVsetValues(vsetCode){
            if (vsetCode === 'PRJ_MASTER_PROJECT') {
                this.masterPrj ={
                    value: null,
                    text: null
                }
            }
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
}