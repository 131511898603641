import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
    name: 'ProjectSectorDetails',
    props: {
        parentProjectSector: Object
    },
    components: {
        DatePicker
    },
    data() {
        return {
            unsubscribe: null,
            showModalComponent: false,
            showValueSetModal: false,
            editMode: false,
            loader: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            masterPrj: {
                value: null,
                text: null,
            },
            sectorCode: null,
            sectorName: null,
            sectorId: 0,
            startDate: null,
            endDate: null,
            start_date_end_date: null,
            version: null,
            approvalStatus: null,
            sequence: null
        }
    },
    mounted() {
        if (this.parentProjectSector) {
            this.fillDataFromParent();
        } else { this.editMode = true }
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode == true) {
                    this.addEditProjectSector();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
                if (actionName === 'upload') {

                    this.eventBus.$emit('commonUpload', { id: this.sectorId });

                }
            }
        });
    },
    methods: {
        fillDataFromParent() {
            this.masterPrj = {
                value: this.parentProjectSector.master_project_id,
                text: this.parentProjectSector.master_project_name
            },
                this.sectorCode = this.parentProjectSector.sector_code;
            this.sectorId = this.parentProjectSector.sector_id;
            this.sectorName = this.parentProjectSector.sector_name;
            this.startDate = this.parentProjectSector.start_date;
            this.endDate = this.parentProjectSector.end_date;
            this.version = this.parentProjectSector.version;
            this.approvalStatus = this.parentProjectSector.approval_status;
            this.sequence = this.parentProjectSector.sequence;
        },
        addEditProjectSector() {
            const payload = {
                master_project_id: this.masterPrj.value,
                sector_code: this.sectorCode,
                sector_id: this.sectorId,
                sector_name: this.sectorName,
                sequence: this.sequence,
                start_date: this.startDate,
                end_date: this.endDate,
            };
            this.loader = true;
            this.$store.dispatch('projects/addEditProjectSector', payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.$emit('updateProjectSectorList');
                        this.showAlert = true;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    }
                    else {
                        this.showAlert = true;
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        disabledDates(date) {
            return commonHelper.disabledDates(
                date,
                this.startDate
            );
        },
        selectedvalueSet(item) {
            if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
                this.masterPrj = {
                    value: item.value_set_dtl_id,
                    text: item.value_code
                }
            }
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === 'PRJ_MASTER_PROJECT') {
                this.masterPrj = {
                    value: null,
                    text: null
                }
            }
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
}